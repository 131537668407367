<template>
  <div id="loginPage">
    <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
    <div class="container">
      <div class="row justify-content-center vh-100">
        <div class="col-lg-10 col-md-10 col-12 align-self-center">
          <div class="card login-form">
            <div class="card-body login-card-body p-0 m-0">
              <div class="row">
                <div class="col-lg-5 col-md-5 col-12 py-5 px-5">
                  <div class="logoBox text-center">
                    <img class="h100" src="../../assets/img/logo.png" />
                  </div>

                  <div class="mt-5">
                    <form v-on:submit.prevent="fnLogin">
                      <div class="input-group mb-3">
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email"
                          v-model="user.email"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                          </div>
                        </div>
                        <div class="invalid-feedback" v-if="errors.email">
                          {{ errors.email[0] }}
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          v-model="user.password"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                          </div>
                        </div>
                        <div class="invalid-feedback" v-if="errors.password">
                          {{ errors.password[0] }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <div class="icheck-primary">
                            <input
                              type="checkbox"
                              id="remember"
                              v-model="user.remember_me"
                            />
                            <label for="remember" class="pl400 fw-500"
                              >Remember Me</label
                            >
                          </div>
                        </div>
                        <div class="col-5">
                          <button type="submit" class="btn btn-info btn-block">
                            <b-spinner
                              small
                              v-if="loading"
                              class="mr-2"
                            ></b-spinner>
                            Sign In
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="mt-3">
                    <p class="mb-1">
                      <router-link to="/forgot-password"
                        >I forgot my password</router-link
                      >
                    </p>
                    <p class="mb-0">
                      <router-link
                        to="/select-register-category"
                        class="text-center"
                        >Create a new account</router-link
                      >
                    </p>
                  </div>
                </div>
                <div
                  class="col-lg-7 col-md-7 col-12 bg-success"
                  id="loginBoxImg"
                >
                  <div class="mt2500 ml800">
                    <h4 class="mb1000">Login to your Individual Account</h4>
                    <div class="d-flex flex-row mb-2">
                      <div class="pr-3">✓</div>
                      <div class="">
                        Unlock all the features of your account including
                        resuming a check, downloading results, and ordering a
                        new check
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="pr-3">✓</div>
                      <div class="">
                        Access your secure online dashboard on any device, 24/7
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="pr-3">✓</div>
                      <div class="">
                        Get real time notifications and status updates about the
                        progress of your police check
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <img src="../../assets/img/login-img.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";

export default {
  name: "LoginPage",
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
      },
      loading: false,
      errors: {},
    };
  },
  computed: {},
  methods: {
    fnLogin: async function () {
      try {
        // Progress bar
        this.$Progress.start();
        this.loading = true;

        const response = await authService.login(this.user);
        this.errors = {};

        const { token_scope, mfa_expire_on_days } = response;

        // Progress bar
        this.$Progress.finish();

        if (token_scope && mfa_expire_on_days === -1) {
          this.$router.push({ name: "verificationMfa" });
        }

        if (token_scope && mfa_expire_on_days === 1) {
          if (token_scope === "admin" || token_scope === "staff") {
            this.$router.push({ name: "dashboardAdmin" });
          } else if (token_scope === "individual-user") {
            this.$router.push({ name: "dashboardIU" });
          } else if (token_scope === "corporate-manager") {
            this.$router.push({ name: "dashboardCM" });
          } else if (token_scope === "corporate-staff") {
            this.$router.push({ name: "dashboardCM" });
          } else if (token_scope === "corporate-customer") {
            this.$router.push({ name: "dashboardCC" });
          } else if (token_scope === "partner") {
            this.$router.push({ name: "dashboardPartner" });
          } else if (token_scope === "partner-staff") {
            this.$router.push({ name: "dashboardPartner" });
          }
        }

        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.loading = false;
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Login",
              error.response.data.message
            );
            break;
          case 403:
            this.loading = false;
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Login",
              error.response.data.message
            );
            this.loading = false;
            break;
          case 422:
            this.loading = false;
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Login",
              error.response.data.message
            );
            break;
          case 500:
            this.loading = false;
            messageService.fnSweetAlertErrorToast(
              "Login",
              error.response.data.message
            );
            break;
          default:
            this.loading = false;
            messageService.fnSweetAlertErrorToast(
              "Login",
              "Something wrong, please try again!"
            );
            break;
        }

        this.loading = false;
        // Progress fail (error)
        this.$Progress.fail();
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  #loginBoxImg img {
    position: inherit;
    right: 0;
    bottom: 0;
    height: 230px;
  }
  .card.login-form {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
</style>
